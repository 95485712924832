// Login.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginRequest,
  loginSuccess,
  loginFailure,
} from "../components/redux/authSlice";
import "../styles.css";
import { Link, Navigate } from "react-router-dom";
import { useNavItem } from "../components/nav/NavContext";
import axios from "axios";
import Logo from "../assets/alta_logo.svg";
import { Col, Input, Spin, message } from "antd";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { setSelectedItem } = useNavItem();
  const { loading, error } = useSelector((state) => state.auth);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const handleLogin = async () => {
    if (!email || !password) {
      message.error("Please enter both email and password.");
      return;
    }

    dispatch(loginRequest());

    try {
      const response = await axios.post(

        "https://altablue.cloud/api/auth/user/login",

       

        { email, password }
      );
      if (response?.status === 200 && response?.data?.success) {

      
        if (response.data.data.user.role === 1) {
          setSelectedItem({
            id: 1,
            url: "https://altablue.cloud/api/companies/all",
          });
        } else if (response.data.data.user.role === 3) {
          setSelectedItem({
            id: 4,
            url: `https://altablue.cloud/api/drivermanagers/companies/${response.data.data.user.id}`,

          });
        }
        dispatch(loginSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(loginFailure(error?.response?.data?.error));
      message.error("Login failed. Please check your credentials.");
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="loginpage">
      <Col xs={20} sm={16} md={12} lg={8} xl={8} className="loginScreen">
        <div className="logo center">
          <img
            style={
              
                { width: "140px" }
                
            }
            src={Logo}
          />
        </div>
        <div className="loginContent">
          <h3>Sign In</h3>
          <p>Sign in to your account to start using Alta Capitale Dashboard</p>
          <div className="loginInputs">
            <div className="loginInput">
              <p>Email</p>
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="loginInput">
              <p>Password</p>
              <Input.Password
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{
                  height: "39px",
                }}
              />
            </div>
            <div className="forgotPassword">
              <Link
                style={{
                  color: "#00ffff",
                }}
                className="forgotPasswordLink"
                to="/forgot-password"
              >
                Forgot Password?
              </Link>
            </div>
            <button
              onClick={handleLogin}
              className="loginButton"
              disabled={loading}
            >
              {loading ? <Spin /> : "Sign In"}
            </button>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default Login;
