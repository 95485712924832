import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../assets/alta_logo.svg'
import { RiLogoutBoxRLine } from "react-icons/ri";
const TopNav = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [smallScreen, setSmallScreen] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 1168) {
          setSmallScreen(false);
        } else {
          setSmallScreen(true);
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <div className="navbar">
      <div>
        <img
          style={
            smallScreen
              ? { width: "140px" }
              : {
                  width: "120px",
                }
          }
          src={Logo}
        />
      </div>
      {smallScreen && (
        <ul className="navbarRightSide">
          {isLoggedIn ? (
            <div className="center">
              <div>{user?.email}</div>

              <Link to="/logout" className="logoutButton center">
                <RiLogoutBoxRLine /> Logout
              </Link>
            </div>
          ) : (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default TopNav;
